$theme-var-app-header-background:	#000000;
$theme-var-app-header-background-text:	#ffffff;
$theme-var-text:	#333333;
$theme-var-link:	#106779;
$theme-var-link-hover:	#253342;
$theme-var-link-active:	#137e94;
$theme-var-info-box-background:	#ffffff;
$theme-var-info-box-heading:	#106779;
$theme-var-info-box-icon:	#106779;
$theme-var-info-box-text:	#333333;
$theme-var-alert-box-success:	#147524;
$theme-var-alert-box-warning:	#c84503;
$theme-var-alert-box-info:	#106779;
$theme-var-alert-box-error:	#e11b46;
$theme-var-alert-box-text:	#ffffff;
$theme-var-form-box-background:	#ffffff;
$theme-var-form-box-text:	#333333;
$theme-var-focus-box-background:	#106779;
$theme-var-focus-box-text:	#ffffff;
$theme-var-brand-header-background:	#ffffff;
$theme-var-brand-footer-background:	#ffffff;
$theme-var-brand-header-background-mobile:	#ffffff;
$theme-var-brand-footer-background-mobile:	#ffffff;
$theme-var-home-background:	#fafafa;
$theme-var-primary-button:	#8b1360;
$theme-var-primary-button-text:	#ffffff;
$theme-var-primary-button-hover:	#5e0d41;
$theme-var-primary-button-hover-text:	#ffffff;
$theme-var-secondary-button:	#127a91;
$theme-var-secondary-button-text:	#ffffff;
$theme-var-secondary-button-hover:	#1388a1;
$theme-var-secondary-button-hover-text:	#ffffff;
$theme-var-tertiary-button:	#333333;
$theme-var-tertiary-button-text:	#ffffff;
$theme-var-tertiary-button-hover:	#1a1919;
$theme-var-tertiary-button-hover-text:	#ffffff;
$theme-var-tab:	#767676;
$theme-var-tab-text:	#ffffff;
$theme-var-tab-active:	#1d1d1d;
$theme-var-tab-active-text:	#ffffff;
$theme-var-tab-hover:	#8b1360;
$theme-var-tab-hover-text:	#ffffff;
$theme-var-menu-background:	#1d1d1d;
$theme-var-menu-text:	#8e96a2;
$theme-var-menu-hover-background:	#141414;
$theme-var-menu-hover-text:	#ffffff;
$theme-var-menu-active-background:	#106779;
$theme-var-menu-active-text:	#ffffff;
$theme-var-tray-background:	#ffffff;
$theme-var-tray-text:	#333333;
$theme-var-tray-link:	#106779;
$theme-var-tray-link-hover:	#253342;
$theme-var-tray-link-active:	#137e94;
